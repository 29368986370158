import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import {
  TextBody,
  TextHeader,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { StepArrowFilter } from 'sections/homepage/sell/stepArrowFilter/StepArrowFilter'
import {
  SCircle,
  SHeader,
  SIcon,
  SLines,
  SNumber,
  SStep,
  SStepContent,
  SStepDetails,
  SStepFilter,
  SStepWrapper,
  SSteps
} from './SellHomepageSteps.styled'
import {
  sellHomepageCircles,
  sellHomepageSteps
} from './SellHomepageSteps.utils'

type Props = {
  title?: string
}

export function SellHomepageSteps({ title }: Props) {
  const { t } = useTranslation(['core', 'sell'])

  const getPosition = (index: number) =>
    index === 0
      ? 'first'
      : index === sellHomepageSteps(t).length - 1
      ? 'last'
      : 'other'

  return (
    <section>
      <SHeader>
        <TextSubhead>{t('sell:steps_lead')}</TextSubhead>
        <Spacer axis="vertical" size={[1, 1, 4]} />
        <TextHeader as="h2" variant={['h5', 'h3', 'h2']}>
          {title || t('sell:steps_title')}
        </TextHeader>
      </SHeader>

      <Spacer axis="vertical" size={8} />

      <SSteps>
        <SLines />
        {sellHomepageSteps(t).map((step, index) => (
          <SStepWrapper key={index} step={index + 1}>
            {sellHomepageCircles.map((circle, i) => (
              <SCircle
                key={i}
                diameter={circle.diameter}
                opacity={circle.opacity}
              />
            ))}
            <SStepFilter>
              <SStep position={getPosition(index)}>
                <SStepContent>
                  <SIcon as={step.icon} />
                  <SStepDetails>
                    <SNumber>{index + 1}</SNumber>
                    <Flex variant="column">
                      <TextSubhead>{step.title}</TextSubhead>
                      <TextBody>{step.content}</TextBody>
                    </Flex>
                  </SStepDetails>
                </SStepContent>
              </SStep>
            </SStepFilter>
            <StepArrowFilter />
          </SStepWrapper>
        ))}
      </SSteps>
    </section>
  )
}
