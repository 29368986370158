import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { radius } from 'driverama-core/styles/variables'

export const Video = styled.video`
  width: 100%;
`

export const SEmbeddedVideo = styled.iframe`
  width: 100%;
  max-width: ${size(320)};
  max-height: calc(100vh - ${size(4)});
  margin: auto;
  aspect-ratio: calc(16 / 9);
  border-radius: ${radius('corner')};

  @media ${media.lte('mobile')} and (orientation: portrait) {
    aspect-ratio: calc(2 / 3);
  }
`
