export const getVideoSource = (language: string) => {
  switch (language) {
    case 'en':
    case 'nl':
      return 'https://www.youtube.com/embed/Rmj-Is1Y1OA'
    case 'de':
    default:
      return 'https://www.youtube.com/embed/T7StZXtU39o'
  }
}
