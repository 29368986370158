import { Interpolation, Theme } from '@emotion/react'
import { PriceYourCarButton } from 'components/priceYourCarButtons/PriceYourCarButton'
import { getUserAuthStatus } from 'driverama-core/analytics/events'
import { Flex } from 'driverama-core/components/Flex'
import { useModal } from 'driverama-core/components/modal/Modal'
import {
  EmbeddedVideo,
  embeddedVpModalStyles,
  vpModalBackgroundStyles
} from 'driverama-core/components/videoPlayer/VideoPlayer'
import { useSession } from 'driverama-core/utils/auth'
import IconPlay from 'images/icons/IconPlay.svg'
import { useTranslation } from 'react-i18next'
import { getVideoSource } from 'sections/aboutUs/aboutVideo/AboutVideo.utils'
import { logGAEvent } from 'utils/analytics/events'
import {
  SButtons,
  SButtonsContainer,
  SPlayButton,
  SPlayButtonContent,
  SPlayButtonImage,
  SPlayButtonLabel
} from './PriceYourCarButtons.styled'

type Props = {
  source: 'upper' | 'lower'
  buttonCss?: Interpolation<Theme>
  hidePlayText?: boolean
}

export function PriceYourCarButtons(props: Props) {
  const { source, buttonCss } = props
  const { t, i18n } = useTranslation('core')
  const { loading, session } = useSession()

  const [modal, openModal] = useModal(
    () => <EmbeddedVideo src={getVideoSource(i18n.language)} />,
    {
      backgroundStyles: vpModalBackgroundStyles,
      closeLabel: t('close'),
      wrapperStyles: embeddedVpModalStyles
    }
  )

  const handleVideoPlayerOpen = async () => {
    logGAEvent('sell_video_watched', {
      ...getUserAuthStatus(session),
      button: source
    })
    await openModal()
  }

  return (
    <>
      {modal}
      <SButtonsContainer>
        <SButtons>
          <PriceYourCarButton source={source} css={buttonCss} />
          <SPlayButton
            disabled={loading}
            type="button"
            onClick={handleVideoPlayerOpen}
          >
            <Flex variant="row" gap={3} align="center">
              <SPlayButtonContent>
                <SPlayButtonImage
                  src="/images/PlayButton@2x.png"
                  layout="fill"
                />
                <IconPlay />
              </SPlayButtonContent>
              {!props.hidePlayText && (
                <SPlayButtonLabel variant="setup">
                  {t('how_it_works')}
                </SPlayButtonLabel>
              )}
            </Flex>
          </SPlayButton>
        </SButtons>
      </SButtonsContainer>
    </>
  )
}
