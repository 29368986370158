import { LINKS } from 'constants/links'
import { getUserAuthStatus } from 'driverama-core/analytics/events'
import { Button } from 'driverama-core/components/button/Button'
import { useSession } from 'driverama-core/utils/auth'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { logGAEvent } from 'utils/analytics/events'
import { logCustomFbEvent } from 'utils/analytics/facebook'
import { logGtmCtaClick } from 'utils/gtm/gtmEvents'

type Props = {
  className?: string
  source?: 'upper' | 'lower'
}

export function PriceYourCarButton(props: Props) {
  const { className, source } = props

  const { t } = useTranslation(['core'])
  const router = useRouter()
  const { loading, session } = useSession()

  return (
    <Button
      variant="secondary"
      className={className}
      disabled={loading}
      onClick={() => {
        logCustomFbEvent('VYKUP_VOZU')

        logGAEvent('wizard_entered', {
          ...getUserAuthStatus(session),
          source_field: source
        })

        logGtmCtaClick('price_car_lower')

        router.push(
          driverama.flags.IS_VIN_ENTER_BUYING_WIZARD_SHUT_DOWN
            ? LINKS.buyingBasicInfoEVYesNo
            : LINKS.buyingBasicInfoVinYesNo
        )
      }}
    >
      {t('core:price_your_car')}
    </Button>
  )
}
