import IconStep1 from 'images/icons/sell/IconStep1.svg'
import IconStep2 from 'images/icons/sell/IconStep2.svg'
import IconStep3 from 'images/icons/sell/IconStep3.svg'
import IconStep4 from 'images/icons/sell/IconStep4.svg'
import IconStep5 from 'images/icons/sell/IconStep5.svg'

import { TFunction } from 'i18next'

export const sellHomepageSteps = (t: TFunction) => {
  return [
    {
      icon: IconStep1,
      title: t('sell:steps_1_title'),
      content: t('sell:steps_1_content')
    },
    {
      icon: IconStep2,
      title: t('sell:steps_2_title'),
      content: t('sell:steps_2_content')
    },
    {
      icon: IconStep3,
      title: t('sell:steps_3_title'),
      content: t('sell:steps_3_content')
    },
    {
      icon: IconStep4,
      title: t('sell:steps_4_title'),
      content: t('sell:steps_4_content')
    },
    {
      icon: IconStep5,
      title: t('sell:steps_5_title'),
      content: t('sell:steps_5_content')
    }
  ]
}

export const sellHomepageCircles = [
  { diameter: 50, opacity: 0.88 },
  { diameter: 58, opacity: 0.66 },
  { diameter: 66, opacity: 0.44 },
  { diameter: 74, opacity: 0.22 },
  { diameter: 82, opacity: 0.22 }
]
