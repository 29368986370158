import { css } from '@emotion/react'

export function StepArrowFilter() {
  return (
    <svg
      css={css`
        visibility: hidden;
        position: absolute;
      `}
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="step-arrow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 16 -7"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
        <filter id="drop-shadow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="shadow" />
          <feOffset in="shadow" dx="0" dy="5" result="shadow" />
          <feColorMatrix
            in="shadow"
            type="matrix"
            values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0.1 0"
            result="shadow"
          />
          <feBlend in="SourceGraphic" in2="shadow" />
        </filter>
      </defs>
    </svg>
  )
}
