import { css } from '@emotion/react'
import { BREAKPOINTS, media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, zIndex } from 'driverama-core/styles/variables'
import { SEmbeddedVideo, Video } from './VideoPlayer.styled'

export function VideoPlayer(props: { src: string }) {
  return (
    <Video controls autoPlay>
      <source src={props.src} type="video/mp4" />
      Your browser does not support the video tag.
    </Video>
  )
}

export function EmbeddedVideo(props: { src: string }) {
  return <SEmbeddedVideo src={props.src} allowFullScreen />
}

export const vpModalStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  max-width: min(calc(100vw - ${size(8)}), 1024px);
  height: auto;
  max-height: min(calc(100vh - ${size(8)}), 800px);

  z-index: calc(${zIndex('notification')} + 1);

  padding: 0;
  overflow: unset;
  background: none;
  box-shadow: none;

  button {
    top: -${size(16)};
    right: 0;

    width: ${size(12)};
    height: ${size(12)};

    border: 1px solid ${color('white', 0.5)};
    border-radius: ${radius('full')};
    color: ${color('white')};

    & svg {
      height: ${size(6)};
      width: ${size(6)};
    }
  }

  @media ${media.lte('MD')} {
    button {
      top: -${size(8)};
      right: ${size(6)};
    }
  }

  @media screen and (max-height: ${BREAKPOINTS.SM}px) and (orientation: landscape) {
    width: calc(150vh - ${size(16)});
    margin: 0 auto;

    button {
      top: 0;
      right: -${size(16)};
    }
  }
`

export const vpModalBackgroundStyles = css`
  background-color: ${color('black', 0.8)};
  z-index: ${zIndex('notification')};
`

export const embeddedVpModalStyles = css`
  ${vpModalStyles}
  width: 100%;
`
