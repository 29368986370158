import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextSubhead } from 'driverama-core/components/text/Text'
import { sideGrid } from 'driverama-core/styles/grid'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

import { getResponsiveStyles } from 'driverama-core/styles/responsivity'
import {
  color,
  font,
  radius,
  shadow,
  zIndex
} from 'driverama-core/styles/variables'

export const sellHPGrid = getResponsiveStyles(['mobile', 'tablet', 'desktop'], {
  desktop: sideGrid(10),
  tablet: sideGrid(4),
  mobile: sideGrid(4)
})

export const SHeader = styled.div`
  text-align: center;
`

export const SSteps = styled.div`
  ${sellHPGrid}

  position: relative;
`

export const SLines = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: content-box;

  width: ${size(1)};
  height: 100%;

  border-left: ${size(1)} solid ${color('sun-l-200')};
  border-right: ${size(1)} solid ${color('sun-l-200')};

  background-color: ${color('white')};

  @media ${media.gt('tablet')} {
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    grid-column-start: col-start 1;
    grid-column-end: col-end 10;

    width: 100%;
    height: ${size(5)};

    border-left: none;
    border-right: none;
    border-top: ${size(16)} solid ${color('night-l-800')};
    border-bottom: ${size(16)} solid ${color('night-l-800')};
  }
`

export const SStepWrapper = styled.div<{ step: number }>`
  position: relative;
  z-index: ${zIndex('order-1')};
  overflow: hidden;

  filter: url('#drop-shadow');

  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  &:not(:last-of-type) {
    margin-bottom: ${size(4)};
  }

  @media ${media.gt('tablet')} {
    ${({ step }) => css`
      grid-column-start: col-start ${(step - 1) * 2 + 1};
      grid-column-end: col-end ${(step - 1) * 2 + 2};
    `}

    &:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: -${size(6)};
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      box-shadow: ${shadow(2)};
      border-radius: ${radius('corner')};
    }
  }
`

export const SStepFilter = styled.div`
  height: 100%;
  filter: url('#step-arrow');
`

export const SStep = styled.div<{ position: 'first' | 'last' | 'other' }>`
  height: 100%;
  background-color: ${color('white')};

  ${({ position }) => css`
    clip-path: ${position === 'first'
      ? 'polygon(50% 0, 100% 0, 100% 90%, 50% 100%, 0 90%, 0 0)'
      : position === 'last'
      ? 'polygon(50% 10%, 100% 0, 100% 100%, 0 100%, 0 0)'
      : 'polygon(50% 10%, 100% 0, 100% 90%, 50% 100%, 0 90%, 0 0)'};

    padding: ${size(position === 'first' ? 8 : 10)} ${size(7)}
      ${size(position === 'last' ? 6 : 10)} ${size(9)};

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding: ${size(8)} ${size(7)} ${size(8)} ${size(9)};
      }
    }
  `}

  @media ${media.gt('tablet')} {
    ${({ position }) => css`
      clip-path: ${position === 'first'
        ? 'polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 0% 0%)'
        : position === 'last'
        ? 'polygon(100% 0, 100% 100%, 0% 100%, 7% 50%, 0% 0%)'
        : 'polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 7% 50%, 0% 0%)'};

      padding: ${size(6)} ${size(position === 'last' ? 7 : 11)} ${size(8)}
        ${size(11)};

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          padding: ${size(6)} ${size(7)} ${size(8)} ${size(7)};
        }
      }
    `}
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      clip-path: none;
    }
  }
`

export const SStepContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;

  ${TextSubhead} {
    margin-bottom: ${size(2)};
  }

  @media ${media.gt('tablet')} {
    flex-direction: column;
    align-items: center;

    ${TextSubhead} {
      margin-bottom: ${size(3)};
    }
  }
`

export const SStepDetails = styled.div`
  position: relative;
  padding-left: ${size(12)};

  @media ${media.gt('tablet')} {
    padding-left: ${size(10)};
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-left: ${size(12)};
    }
  }
`

export const SIcon = styled.svg`
  height: ${size(8)};
  width: ${size(8)};

  flex-shrink: 0;

  color: ${color('sun')};

  @media ${media.gt('tablet')} {
    height: ${size(12)};
    width: ${size(12)};

    margin-bottom: ${size(9)};
  }
`

export const SNumber = styled.span`
  position: absolute;
  top: 0;
  left: 0;

  color: ${color('night-l-650')};
  font-family: ${font('heading')};
  font-size: ${size(13)};
  line-height: ${size(14)};
`

export const SCircle = styled.div<{ opacity: number; diameter: number }>`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: ${zIndex('order-2')};

  opacity: 0.3;
  border-radius: ${radius('full')};

  ${({ opacity, diameter }) => css`
    height: ${size(diameter)};
    width: ${size(diameter)};
    border: 1px solid ${color('sun-l-200', opacity)};
  `}

  @media ${media.gt('tablet')} {
    right: initial;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
