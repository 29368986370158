import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, time } from 'driverama-core/styles/variables'
import Image from 'next/image'

export const SButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${media.gt('tablet')} {
    flex-direction: column;
    margin-top: 0;
  }
`

export const SButtons = styled.div`
  display: flex;
  gap: ${size(4)};
  width: 100%;
`

export const SPlayButtonImage = styled(Image)`
  transition: ${time('control')} opacity;
  opacity: 0.6;
`

export const SPlayButton = styled(ButtonTransparent)`
  &:disabled {
    opacity: 0.4;
  }

  @media (hover: hover) {
    &:hover ${SPlayButtonImage} {
      opacity: 1;
    }
  }
`

export const SPlayButtonContent = styled.span`
  position: relative;
  display: grid;

  width: ${size(14)};
  height: ${size(14)};
  flex-shrink: 0;

  background: ${color('night-l-100')};

  border-radius: ${radius('corner')};
  overflow: hidden;

  color: ${color('white')};
  place-items: center;

  & > * {
    grid-row: 1;
    grid-column: 1;
    position: relative;
  }
`

export const SPlayButtonLabel = styled(TextBody)`
  color: ${color('night-l-100')};

  @media ${media.lte('tablet')} {
    display: none;
  }
`
